import React from 'react';
import { useMediaQuery } from '@mui/material';
import ResWhyBan from '../components/ResWhyBan';
import DropdownList from '../components/ResDrop';

function MyComponent() {
  const isDesktop = useMediaQuery('(min-width: 800px)');

  return (
    <div>
      {isDesktop ? <ResWhyBan /> : <DropdownList />}
    </div>
  );
}

export default MyComponent;