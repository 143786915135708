import React, { useState } from 'react';
import { Grid, Button } from '@material-ui/core';
// import img1 from '../media/image1.png';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import KeyboardArrowDownSharpIcon from '@mui/icons-material/KeyboardArrowDownSharp';
import cloud from '../media/cloud.png';
import hand from '../media/hand.png';
import chart from '../media/chart.png';
import file from '../media/file1.png';


const theme = createTheme({
  palette: {
    primary: {
      main: 'rgba(36, 59, 86, 0.5)',
    },
    default: {
      main: 'rgba(36, 59, 86, 1)',
    },
  },
});

const options = [
  {
    id: 1,
    label: 'For Digital Dentistry Companies.',
    content: { obj1 :
                        { img: hand,
                    heading: 'Accessible Storage',
                    text: 'Easily store hundreds of millions of dental records and CAD files. Offers a quick and inexpensive system for importing/exporting iCAD files.', 
                        },

                    obj2 :{ img: chart,
                    heading: 'Efficient Workflow',
                    text: 'With guided prep and the iCAD system, dental processes now generate shorter lead times allowing dentists to treat more patients, and maintain better overall patient satisfaction.​​', 
                        },

                    obj3 :{ img: cloud,
                    heading: 'Remote Dentistry',
                    text: 'Creates access to oral healthcare to low income communities which brings in more patients and provides a convenient service for both the dentist and patient.​​', 
                        },

}
        
  },
  {
    id: 2,
    label: 'For Dentists.',
    content: { obj1 :
                        { img: file,
                    heading: 'Pay per CAD',
                    text: 'This model is more accessible and affordable upfront, leading to an increase in customer base, larger customer retention will increase the occurrence of transactions/ customer.', 
                        },

                    obj2 :{ img: cloud,
                    heading: '.iCAD & Cloud',
                    text: 'Our file format & cloud service will not be monetized. They are free to use for high volume storage & are compatible with many file types & scanning/crown production softwares.​', 
                        },

                    obj3 :{ img: hand,
                    heading: 'Collect Data',
                    text: 'iCAD cloud platform has the ability to collect data on dentists and their practices. This can apply to marketing efforts and creating targeted campaigns for specific offices.​', 
                        },
}
  },
];

const ResWhyBan = () => {
  const [selectedOption, setSelectedOption] = useState(options[1]);
  const [showOptionText, setShowOptionText] = useState(false);

  const handleButtonClick = (option) => {
    if (selectedOption === option && showOptionText) {
      setShowOptionText(false);
    } else {
      setSelectedOption(option);
      setShowOptionText(true);
    }
  };

  return (
    <div>
      <div style={{ fontFamily: "Public Sans, sans-serif" }} id='drop-title'>
          <font id='drop-title-1' color="#243B56">Why </font> 
          <font id='drop-title-2' color="#55B9D5"><b>iCAD?</b></font>
        </div> 
    
    <Grid style={{ fontFamily: "Inter, sans-serif" }} id='drop-parent-grid' container spacing={2}>
      <Grid item xs={0} >
        <Grid id='drop-btn-grid-parent' container direction="column" justifyContent="flex-start" spacing={2}>
          {options.map((option) => (
            <>
            <Grid id='drop-btn-grid' item key={option.id}>
              <ThemeProvider theme={theme}>

                  <Button id='drop-btn'
                    className="btn-align-start"
                    variant="text"
                    color={selectedOption?.id === option.id ? 'default' : 'primary'}
                    onClick={() => handleButtonClick(option)}
                    fullWidth
                    style={{textTransform: 'none'}}
                  >
                    {option.label}
                  </Button>
                  <KeyboardArrowDownSharpIcon id='drop-icon-btn' onClick={() => handleButtonClick(option)}/>

              </ThemeProvider>
            </Grid>
            <div id='drop-line'></div>
            </>
          ))}
        </Grid>
      </Grid>
      <Grid id='drop-text-flex-parent' item xs={8}>
        {selectedOption && showOptionText && (
          <div>
            <div id='drop-text-flex'>
                <div id='drop-text-flex-child'>
                    <img id='drop-child-icon' src={selectedOption.content.obj1.img}  alt='logo'/>
                    <p id='drop-text-head'><font  color="#243B56" ><b>{selectedOption.content.obj1.heading} </b></font> </p>
                    <p id='drop-text'><font  color="#243B56" >{selectedOption.content.obj1.text}</font> </p>
                </div>

                <div id='drop-text-flex-child'>
                    <img id='drop-child-icon' src={selectedOption.content.obj2.img}  alt='logo'/>
                    <p id='drop-text-head'><font  color="#243B56" > <b>{selectedOption.content.obj2.heading} </b></font> </p>
                    <p id='drop-text'><font  color="#243B56" >{selectedOption.content.obj2.text}</font> </p>
                </div>

                <div id='drop-text-flex-child'>
                    <img id='drop-child-icon' src={selectedOption.content.obj3.img}  alt='logo'/>
                    <p id='drop-text-head'><font  color="#243B56" ><b>{selectedOption.content.obj3.heading}</b> </font> </p>
                    <p id='drop-text'><font  color="#243B56" >{selectedOption.content.obj3.text}</font> </p>
                </div>
            </div>
          </div>
        )}
      </Grid>
    </Grid>
  </div>
  );
};

export default ResWhyBan;
