import ban from '../media/banback.png'

export default function LabBan() {
    return (
        <div className='res-ban' >
                
                <img id='res-background-img' src={ban}  alt='logo'/>
                <div className="res-ban-overlay">
                <div className="res-text-container">
                    <p style={{ fontFamily: "Public Sans, sans-serif" }} id='res-text-container-heading-1'><b>Research</b> & <b>Innovation</b></p>
                    <p style={{ fontFamily: "Inter, sans-serif" }} id='res-text-container-heading-2'>Our patented AiCAD Dental system uses Augmented Intelligence to extract design files from a digital 
                                                database with speed and accuracy beyond conventional CAD workflows. It can download high-resolution and small-sized design files in 
                                                seconds and guide dentist's hands for optimal treatments/surgeries.</p>

                </div>
            </div>
        </div>
      );
}