import React from 'react';

const GoogleMap = () => {
  return (
    <div id='map'>
    <iframe
      title="1825 Woodward Drive Ottawa, ON K2C 0P9"
      width="100%"
      height="450"
      frameBorder="0"
      src={`https://maps.google.com/maps?q=1825%20Woodward%20Drive%20Ottawa%2C%20ON%20K2C%200P9&t=&z=13&ie=UTF8&iwloc=&output=embed`}
      allowFullScreen
    />
    </div>
  );
};

export default GoogleMap;