import '../css/CTPPage/ctpvideoban.css'


import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import CTPVideo from '../components/CTPVideo';


function CtpPage() {
    return (
    <div>
        <NavBar/>
        <CTPVideo/>
        <Footer/>
      </div>
    );
  }
  
export default CtpPage;