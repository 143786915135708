// import bluelogo from '../media/BlueLogo.png'
import React from 'react';
import VimeoPlayer from './VimeoPlayer';
import VimeoIconPlayer from './VimeoVidIcons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilePowerpoint } from '@fortawesome/free-solid-svg-icons'

export default function CtpVideoBan() {

    const videoUrl1 = 'https://vimeo.com/829514271?share=copy';
    const videoUrl2 = 'https://vimeo.com/829821494?share=copy';
    const videoUrl3 = 'https://vimeo.com/829576627?share=copy';
    const videoUrl4 = 'https://vimeo.com/829826824?share=copy';

    return (
        <div className='ctp-video-ban'>
        <br/><br/><br/><br/><br/>
        <div id='ctp-video-title'>
            <font style={{ fontFamily: "Public Sans, sans-serif" }} id='ctp-video-title-1' color="#243B56">Clinical Testing </font> 
            <font style={{ fontFamily: "Public Sans, sans-serif" }} id='ctp-video-title-2' color="#26466D"><b>Platform.</b></font>
            <p id='ctp-video-text'>
                <font style={{ fontFamily: "Inter, sans-serif" }} color="#243B56">The Cad2Cam Clinical Research Platform connects dentists with 
                cutting-edge technologies and resources, in order to collect feedback and develop expertise on how to employ AI in new efficient 
                and optimized digital dentistry workflows. 
                                                
                </font>
                    </p> 
        </div>
        <div className='ctp-yt-video-parent'>
            <div className='ctp-yt-video-child1'>
                <VimeoPlayer videoUrl={videoUrl1}/>
            </div>
            
            <div className='ctp-yt-video-child2'>
                <a href='https://sway.office.com/EkB5FoifCDhomAZk?ref=Link'>
                    <p id='ctp-ppt-link'>
                    <FontAwesomeIcon style={{marginRight:'5%'}} color="#26466D" icon={faFilePowerpoint} />
                       
                    <font style={{ textDecoration: 'underline' }} color="#26466D">View Presentation Here</font></p>
                </a>
            </div>
        </div>
        <p id='ctp-video-text'>
            <font style={{ fontFamily: "Inter, sans-serif", fontWeight: 600, marginLeft: "21.5%" }} color="#243B56">Explore our video library:                                                
            </font>
        </p> 
        
        
        <div id='ctp-more-video-parent-icon'>
                
                <div id='ctp-more-video-child-icon'>
                    <VimeoIconPlayer videoUrl={videoUrl2}/>
                    <p id='video-icon-title'>
                        <font style={{ fontFamily: "Inter, sans-serif", fontWeight: 500}} color="#243B56">Just in Time Crown Manufacturing                                                
                        </font>
                    </p> 
                </div>

                <div id='ctp-more-video-child-icon'>
                    <VimeoIconPlayer videoUrl={videoUrl3}/>
                    <p id='video-icon-title'>
                        <font style={{ fontFamily: "Inter, sans-serif", fontWeight: 500 }} color="#243B56">image Guided Prep Surgery                                                
                        </font>
                    </p> 
                </div>

                <div id='ctp-more-video-child-icon'>
                    <VimeoIconPlayer videoUrl={videoUrl4}/>
                    <p id='video-icon-title'>
                        <font style={{ fontFamily: "Inter, sans-serif", fontWeight: 500 }} color="#243B56">Segmented Surface Matrices                                               
                        </font>
                    </p> 
                </div>

        </div>
        </div>
      );
}
