import React from 'react';
import ReactPlayer from 'react-player';


const VimeoIconPlayer = ({ videoUrl }) => {
    
    return (
      <div id='vim-vid-div'>
        <ReactPlayer id='vim-vid' controls={true} width="100%" height="auto" url={videoUrl} />
      </div>
    );
  }
  
  export default VimeoIconPlayer;
  