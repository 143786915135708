// import bluelogo from '../media/BlueLogo.png'
import React from 'react';
import CBpdf from '../media/C&BRx.pdf'
import Dpdf from '../media/DenRx.pdf'


export default function LabPrint() {

    return (
        <div id= "rx" className='print-ban'>
           <div style={{ fontFamily: "Public Sans, sans-serif" }} id='print-ban-heading'>
                <font id='print-ban-heading-1' color="#243B56">Laboratory </font> 
                <font id='print-ban-heading-2' color="#5CB099"><b>Prescriptions.</b></font>
            </div>
            <div style={{ fontFamily: "Inter, sans-serif" }} id='print-ban-text'>
             
                <font id='print-ban-text-1' color="#22303E">Smile Dental Laboratory has experienced significant growth over the last decade driven in large part by the strong 
                relationships built with dentists in the Ottawa/Gatineau region and the passionate commitment to a quality product. 
                <br/>
                <br/>
                <br/>
                
                Download prescriptions here: 
                <br/>
                <br/>
                </font>
                   
            </div>
            <div id='print-ban-text-list'>
            <div  style={{ fontFamily: "Inter, sans-serif" }} id='print-ban-text-list-item' >
                    <a href={CBpdf} target="_blank" rel="noopener noreferrer">
                        <font color="#5CB099"><b>Crown and Bridge Rx</b>
                        </font>
                    </a> 
                <br/>
            </div>
            {/* <div style={{ fontFamily: "Inter, sans-serif" }} id='print-ban-text-list-item'><font color="#5CB099"><b>Fillings</b></font><br/></div> */}
            <div style={{ fontFamily: "Inter, sans-serif" }}  id='print-ban-text-list-item'>
                    <a href={Dpdf} target="_blank" rel="noopener noreferrer">
                        <font color="#5CB099"><b>Denture Rx</b>
                        </font>
                    </a>
                <br/>
            </div>
        </div>


           
        </div>
      );
}
