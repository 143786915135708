import lab from '../media/lab.png'
import cad from '../media/cad_bg.png'

import research from '../media/research.png'
import { Link } from 'react-router-dom';


export default function LRBan() {
  
    return (
        <div className='lab-res-ban'>
           <div id="cad-tag">

           <a  href="ctp" component={Link} to="/ctp"><img id='cad-img' src={cad}  alt='logo'/> 

                <div style={{ fontFamily: "Inter, sans-serif" }} id="cad-tag-text">
                    
                    <p id='cad-img-text-1'><font >Clinical Testing Platform</font> </p>

                    <p id='cad-img-text-2'><font >Our Clinical Testing Platform connects dentists with the most Innovative Cutting-Edge Dentistry Tools.  </font> </p>
                    
                    <p id='cad-img-text-3'><font ><b>Explore > </b></font> </p>

                </div> </a>
           </div>

           <div id="research-tag">

           <a  href="research" component={Link} to="/research"><img id='research-img' src={research}  alt='logo'/> 

                <div style={{ fontFamily: "Inter, sans-serif" }} id="research-tag-text">
                    
                    <p id='research-img-text-1'><font >Research and Innovation</font> </p>

                    <p id='research-img-text-2'><font >Our Center for Research and Innovation is Internationally know for Advances in Dental Technologies.  </font> </p>
                    
                    <p id='research-img-text-3'><font ><b>Explore > </b></font> </p>

                </div> </a>
           </div>

           <div id="lab-tag">
           <a href="lab" component={Link} to="/lab"><img id='lab-img' src={lab}  alt='logo'/> </a>

                  <div style={{ fontFamily: "Inter, sans-serif" }} id="lab-tag-text">

                      <p id='lab-img-text-1'><font >Lab Services</font> </p>

                      <p id='lab-img-text-2'><font >Our Dental Laboratory designs and fabricates Crowns and Dental Implants using a variety of methods.  </font> </p>
                      
                      <p id='lab-img-text-3'><font ><b>Explore > </b></font> </p>

                  </div>

           </div>
               
        </div>
      );
}


// import lab from '../media/lab.png'
// import research from '../media/research.png'

// export default function LRBan() {
//     return (
//         <div className='lab-res-ban' style={{display: 'flex'}}>
//            <div id="research-tag" style={{flex: 1, display: 'flex', flexDirection: 'column'}}>

//            <a href='https://www.google.com'><img id='research-img' src={research}  alt='logo' style={{height: '60vh', width: '100%'}}/> </a>

//            <p id='research-img-text-1'><font  color="#FFFFFF" style={{fontSize:'3.2vw',fontWeight:'600'}}>Research and Innovation</font> </p>

//            <p id='research-img-text-2'><font  color="#FFFFFF" style={{fontSize:'1.8vw',fontWeight:'300'}}>Our Center for Research and Innovation is the Internationally know for Advances in Dental Technologies.  </font> </p>
           
//            <p id='research-img-text-3'><font  color="#FFFFFF" style={{fontSize:'1.8vw',fontWeight:'300'}}>Explore > </font> </p>
//            </div>

//            <div id="lab-tag" style={{flex: 1, display: 'flex', flexDirection: 'column'}}>
//            <a href='https://smiledentallaboratory.ca/'><img id='lab-img' src={lab}  alt='logo' style={{height: '60vh', width: '100%'}}/> </a>

//            <p id='lab-img-text-1'><font  color="#FFFFFF" style={{fontSize:'3.2vw',fontWeight:'600'}}>Lab Services</font> </p>

//            <p id='lab-img-text-2'><font  color="#FFFFFF" style={{fontSize:'1.8vw',fontWeight:'300'}}>Our Dental Laboratory designs and fabricates Crowns and Dental Implants using a variety of methods.  </font> </p>
           
//            <p id='lab-img-text-3'><font  color="#FFFFFF" style={{fontSize:'1.8vw',fontWeight:'300'}}>Explore > </font> </p>

//            </div>
               
//         </div>
//       );
// }