import '../App.css';
import '../css/navbar.css';
import '../css/ResearchPage/res-ban.css';
import '../css/ResearchPage/res-found-ban.css';
import '../css/ResearchPage/res-why-ban.css';
import '../css/ResearchPage/res-why-mobile.css';
import '../css/ResearchPage/res-contact.css';
import '../css/ResearchPage/res-link.css';

import '../css/footer.css';
import '../css/carousel.css';

import NavBar from '../components/NavBar';
import ResearchBan from '../components/ResearchBan';
import Footer from '../components/Footer';
import ResFoundBan from '../components/ResFoundation';
import ResCondition from '../components/ResCondition';
import ResContact from '../components/ResContact';
import Carousel from '../components/Carousel';


function ResearchPage() {
    return (
    
    <div>
        <NavBar/>
        <ResearchBan/>
        <ResFoundBan/>
        <Carousel/>
        {/* <ResLink/> */}
        <ResCondition/>
        {/* <ResWhyBan/> */}
        {/* <DropdownList/> */}
        <ResContact/>
        {/* <Construction/> */}
        <Footer/>
      </div>
    );
  }
  
  export default ResearchPage;