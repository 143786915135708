import bluelogo from '../media/BlueLogo.png'


export default function FooterBan() {
    return (
        <div className='footer-ban'>
           <img id='footer-img' src={bluelogo}  alt='logo'/>
        </div>
      );
}
