import cengiz from '../media/cengiz1.png'
import burak from '../media/burak1.png'

export default function LabOwn() {
    return (
        <div className='lab-own-ban'>
              <div id='lab-own-ban-flex-wrapper'>

                <div id='lab-own-ban-flex-1'>
                    <div style={{ fontFamily: "Public Sans, sans-serif" }} id='lab-own-ban-flex-1-heading-box-1'>
                        <font id='lab-own-ban-flex-1-heading-1' color="#243B56">Lab Services Founded in </font> 
                        <font id='lab-own-ban-flex-1-heading-2' color="#5CB099"><b>2005.</b></font>
                    </div>
                    <div id='lab-own-ban-flex-2-heading-box-2'>
                        <p id='lab-own-ban-flex-2-text'>
                        <font style={{ fontFamily: "Inter, sans-serif" }} color="#243B56">Smile Dental Laboratory has experience significant growth over last decade 
                                building strong relationships with dentists based on the utmost quality of 
                                their products.  iCAD is continuing Cengiz’ and Burak’s commitment to offer 
                                a full range of quality dental laboratory services while leveraging the added 
                                team of 7 engineers and 14 digital dentistry technicians to create a skill set 
                                of this kind. 
                        </font>
                            </p> 

                    </div>
                </div>
                </div>
                <div id='lab-own-ban-flex-2'>

                    <div id='child-pic-1'>
                        <img id='cengiz-img' src={cengiz}  alt='logo'/>

                    </div>

                    <div id='child-pic-1'>
                        <img id='cengiz-img' src={burak}  alt='logo'/>
                    </div>
                </div>

        </div>
      );
}