import qr1 from '../media/qr1.png'
import qr2 from '../media/qr2.png'
import qr3 from '../media/qr3.png'
import qr4 from '../media/qr4.png'
import qr5 from '../media/qr5.png'
import qr6 from '../media/qr6.png'

import '../css/ResearchPage/res-link.css';

import React from "react";


export default function ResLinkBan() {
    return (

        <div className='link-ban-flex' >
                
                <div style={{ fontFamily: "Inter, sans-serif" }} className="link-ban-flex-parent">

                <div id='link-ban-flex-parent1'>

                <a href='https://sway.office.com/AVSeD0irqN96p6xh?ref=Link'>
                        <div className="link-ban-flex-child">
                            
                                <img id='qr-img' src={qr2}  alt='logo'/>
                        
                            <p id='qr-text'>iCAD PaaS</p>
                        </div>
                    </a>

                    <a href='https://sway.office.com/56dVbo9Dq8UAHE4u?ref=Link'>
                        <div className="link-ban-flex-child">
                            
                                <img id='qr-img' src={qr1}  alt='logo'/>
                            
                            <p id='qr-text'>AiCAD SaaS</p>
                        </div>
                    </a>

                </div>

                <div id='link-ban-flex-parent2'>

                    <a href='https://sway.office.com/obToxnZgCGWWxQpy?ref=Link'>
                        <div className="link-ban-flex-child">
                            

                                <img id='qr-img' src={qr3}  alt='logo'/>
                            
                            <p id='qr-text'>Rapid Crown Design</p>
                        </div>
                    </a>
                
                    <a href='https://sway.office.com/6lu9kQeq5U3UkWhL?ref=Link'>
                    <div className="link-ban-flex-child">
                        
                            <img id='qr-img' src={qr4}  alt='logo'/>
                    
                        <p id='qr-text'>Rapid Prep Design</p>
                    </div>
                </a>

                </div>
                
                <div id='link-ban-flex-parent3'>
                

                <a href='https://sway.office.com/GfA9vXREwerQNbMA?ref=Link'>
                    <div className="link-ban-flex-child">
                        
                            <img id='qr-img' src={qr5}  alt='logo'/>
                        
                        <p id='qr-text'>iGPS System</p>
                    </div>
                </a>
                <a href='https://sway.office.com/GC914KFy5ubacXnm?ref=Link'>
                    <div className="link-ban-flex-child">
                        
                            <img id='qr-img' src={qr6}  alt='logo'/>
                        
                        <p id='qr-text'>iGPS Workflow</p>
                    </div>
                </a>
                </div>
                </div>

        </div>

      );
}