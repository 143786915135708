
import React, { useState, useEffect } from 'react';
import BanVideo from '../media/icad-custom-banner_1.mov'
import ban from '../media/ban.png'

export default function VideoBan() {

    const [RbackgroundVideo, RsetBackgroundVideo] = useState(BanVideo);
    const [Rimage, RsetImage] = useState(ban);

    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const handleResize = () => {
          setIsMobile(window.innerWidth < 800);
          if (window.innerWidth < 800) {
            RsetBackgroundVideo("");
            RsetImage(ban);

          } else {
            RsetBackgroundVideo(BanVideo);
            RsetImage("");

          }
        };

        handleResize();
        window.addEventListener("resize", handleResize);
        return () => {
          window.removeEventListener("resize", handleResize);
        };
      }, []);
    return (
        <div className='video-ban' >

                {isMobile ? (
                            <img id='test-img' src={Rimage}  alt='logo'/>
                        ) : (
                            <video autoPlay loop muted id="background-video" poster="../media/lab.png">
                                  <source src={RbackgroundVideo} type="video/mp4"/>
                              </video>
                        )}          

                <div className="overlay">
                <div className="text-container">
                    <p style={{ fontFamily: "Public Sans, sans-serif" }} id='heading-1'><span style={{ fontWeight: 20 }}>The Future of </span><span style={{ fontWeight: 700 }}>Dentistry.</span></p>
                    <p style={{ fontFamily: "Inter, sans-serif" }} id='heading-2'>Following a global trend for all manufacturing industries, most dental appliances are soon going to be 3D printed chairside.
                                                                                    Artificial Intelligence holds the key to providing readily available design files that can 
                                                                                        be customized to match the mechanical properties of any available 3D printing material.</p>

                </div> 
                  
                </div>
        </div>
      );
}
