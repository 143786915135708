import img1 from '../media/image1.png'
import img2 from '../media/image2.png'
import img3 from '../media/image3.png'
import img4 from '../media/image4.png'
import img5 from '../media/image5.png'

export default function FeatureBan() {
    return (
        <div className='feature-ban'>
            
                <p style={{ fontFamily: "Public Sans, sans-serif" }} id="accomplish-text"> 
                    <font id='feature-heading1' color="#243B56" >What can you accomplish with </font> 
                    <font  id='feature-heading2' color="#55bad5" ><span style={{ fontWeight: 900 }}>iCAD.</span></font>
                </p>
                
                <div id='feature-flex-parent'>

                    <div id='flex-child'>
                    <img id='flex-child-image' src={img1}  alt='logo'/>
                    <p style={{ fontFamily: "Public Sans, sans-serif" }} id='feature-ban-text-0'><font  color="#243B56" >Computer Aided Design & 3D Print </font> </p>
                    <p style={{ fontFamily: "Inter, sans-serif" }} id='feature-ban-text'><font  color="#243B56" >A dedicated digital dentistry department that specializes in CAD & 3D printing.</font> </p>

                    </div>
                    <div id='flex-child'>
                    <img id='flex-child-image' src={img2}  alt='logo'/>
                    <p style={{ fontFamily: "Public Sans, sans-serif" }} id='feature-ban-text-0'><font  color="#243B56" >Dental Lab Services & Crown Production </font> </p>
                    <p style={{ fontFamily: "Inter, sans-serif" }} id='feature-ban-text'><font  color="#243B56" >Our team, with years of expertise, produces dental prosthetics of the highest quality.</font> </p>

                    </div>
                    <div id='flex-child'>
                    <img id='flex-child-image' src={img3}  alt='logo'/>
                    <p style={{ fontFamily: "Public Sans, sans-serif" }} id='feature-ban-text-0'><font  color="#243B56" >Clinical Testing & Research </font> </p>
                    <p style={{ fontFamily: "Inter, sans-serif" }} id='feature-ban-text'><font  color="#243B56" >We thrive on technology and innovation, constantly staying ahead of the industry.</font> </p>

                    </div>
                </div>

                <div id='feature-flex-parent-2'>
    
                    <div id='flex-child-2'>
                        <img id='flex-child-image' src={img4}  alt='logo'/>
                        <p style={{ fontFamily: "Public Sans, sans-serif" }} id='feature-ban-text-0-2'><font  color="#243B56" >Cloud Storage for Dental Files </font> </p>
                        <p style={{ fontFamily: "Inter, sans-serif" }} id='feature-ban-text-2'><font  color="#243B56" >Cloud-based solution for storing large dental files, which allows to migrate dental software to web.</font> </p>

                        </div>
                        <div id='flex-child-2'>
                        <img id='flex-child-image' src={img5}  alt='logo'/>
                        <p style={{ fontFamily: "Public Sans, sans-serif" }} id='feature-ban-text-0-2'><font  color="#243B56" >Machine Learning & AI Services </font> </p>
                        <p style={{ fontFamily: "Inter, sans-serif" }} id='feature-ban-text-2'><font  color="#243B56" >Our file format enables us to cluster & recognize patterns in dental anatomies.</font> </p>

                    </div>
                </div>
        </div>
      );
}