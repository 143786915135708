


export default function OrgChartHeader() {

    return (

        <div className='org-ban'>

            <p style={{ fontFamily: "Inter, sans-serif" }} id="org-head-text"> 
                {/* <font id='org-heading1' color="#243B56" >Organizational </font> 
                <font id='org-heading2' color="#55bad5" >Chart. </font>  */}
                <font id='org-heading1' color="#243B56" >iCAD's Founders.</font> 
            
            </p>
            {/* <p id="org-subhead-text"> 
                <font style={{ fontFamily: "Inter, sans-serif" }} id='org-subheading' color="#243B56" >iCAD's Team. </font> 
            </p> */}
       
        </div>
      );
}
