import smile from '../media/BrashLogo1.png'
import mila from '../media/Mila.png'

export default function PartnersBan() {
    return (
        <div className='partner-ban'>
           <p id="partners-text"> 
                <font style={{ fontFamily: "Inter, sans-serif" }} id='partners-text-1' color="#243B56" >Our Partners. </font> 
                </p>
            <div id='parent-flex-partner'>
                <div id='child-flex-partner'>
                <a href="https://mila.quebec/en/">
                    <img id='mila-child-image' src={mila}  alt='logo'/>
                    </a>
                </div>
                <div id="child-flex-partner">
                <a href="https://www.brashinc.com/">
                    <img id='smile-child-image' src={smile}  alt='logo'/>
                    </a>
                </div>
            </div>
        </div>
      );
}
