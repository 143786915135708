import '../App.css';
import '../css/navbar.css';
import '../css/video-ban.css';
import '../css/feature-ban.css';
import '../css/vision-ban.css';
import '../css/partners.css';
import '../css/lab-research-ban.css';
import '../css/contact.css';
import '../css/footer.css';
import '../css/styles.css';
import '../css/team-carousel.css'; 

import "../css/chart.css";
// import NavBar from './components/NavBar';
import NavBar from '../components/NavBar';
import VideoBan from '../components/VideoBan';
import FeatureBan from '../components/FeaturesBan';
import OrgChartHeader from '../components/OrgChart';
// import Chart from '../components/Chart.jsx';
import TeamCarousel from '../components/TeamCarousel';

import VisionBan from '../components/VisionBan';
import LRBan from '../components/LabResearchTag';
import PartnersBan from '../components/Partners';
import CoontactBan from '../components/Contact';
import FooterBan from '../components/Footer';


function HomePage() {
  return (
    // <div className="App">
    <div>
      <NavBar/>
      <VideoBan/>
      <VisionBan/>
      <FeatureBan/>
      <OrgChartHeader/>
      {/* <Chart/> */}
      <TeamCarousel/>
      <PartnersBan/>
      <LRBan/>
      <CoontactBan/>
      <FooterBan/>
    </div>
  );
}

export default HomePage;
