import foundstack from '../media/found.png'
import foundmobile from '../media/FoundStack.png'
import React, { useState, useEffect } from 'react';


export default function ResFoundBan() {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 700);

    useEffect(() => {
        function handleResize() {
          setIsMobile(window.innerWidth <= 700);
        }
    
        window.addEventListener('resize', handleResize);
    
        return () => window.removeEventListener('resize', handleResize);
      }, []);
      
    return (
        <div className='res-found-ban' >
            <div id='res-found-ban-title-wrapper'>
                <div id='res-found-ban-title'>
                    <font style={{ fontFamily: "Public Sans, sans-serif" }} id='res-found-ban-title-1' color="#243B56">The </font> 
                    <font style={{ fontFamily: "Public Sans, sans-serif" }} id='res-found-ban-title-2' color="#55B9D5"><b>Foundation.</b></font>
                    <p style={{ fontFamily: "Inter, sans-serif" }} id='res-found-ban-text'>
                        <font  color="#555555">AI and 3D Printing are the future of Digital Dentistry. 
                                            The real questions is when and how both at these technologies are going to take over the dental industry. 
                        </font>
                            </p> 
               </div>
               </div>

               <p style={{ fontFamily: "Public Sans, sans-serif" }} id='res-found-ban-subtitle'><font color="#55B9D5"><b>iCAD Stack Base</b></font> </p>

               <div id='res-found-parent-2'>

                    <div id='res-found-child-1'>
                    <p style={{ fontFamily: "Inter, sans-serif" }} id='res-found-subtitle-1'><font color="#22303E">iCAD Dental Platform(Paas)</font> </p>
                    <p style={{ fontFamily: "Inter, sans-serif" }} id='res-found-subtext'><font color="#555555">Our patented encoding method transforms STL files into 
                                                                                    “Segmented Surface Matrixes” (SSM), which map all 3D anatomical features consistently. Design files are easily 
                                                                                    created by allocating corresponding data values from a database and patient scan. Using an oral scanner, a small 
                                                                                    dataset is populated to represent the patient’s pattern. The dataset is much smaller than the corresponding STL 
                                                                                    file, making it faster to upload to iCAD’s PaaS</font> </p>
                    </div>

                    <div id='res-found-child-2'>
                        <img id='stack-found-image' src={isMobile ? foundmobile : foundstack}  alt='logo'/>
                    </div>

                    <div id='res-found-child-3'>

                    <p style={{ fontFamily: "Inter, sans-serif" }} id='res-found-subtitle-1'><font color="#076DB5">AiCAD Dental Software(Saas)</font> </p>

                    <p style={{ fontFamily: "Inter, sans-serif" }} id='res-found-subtext'><font color="#555555">Once a patient's dental pattern is uploaded, our AiCAD system takes over, 
                                                            leveraging Deep Learning algorithms to extract complex data from a large .icad database. 
                                                            It can incorporate hundreds of dental features simultaneously, making the design process many 
                                                            orders of magnitude faster than other methods. By matching a patient's dental pattern with a 
                                                            pattern from the database, the design process can be expedited almost instantly using a cloud 
                                                            application with a Pay-Per-CAD business model.</font> </p>

                    </div>

               </div>

               <div id='found-stack-flex'>

                    <div id='found-stack-text'> 
                        
                        <p style={{ fontFamily: "Inter, sans-serif" }} id='res-found-ban-subtitle-1'><font id='res-found-ban-subtitle-1' color="#076DB5">AiCAD Dental Software(Saas)</font> </p>

                        <p style={{ fontFamily: "Inter, sans-serif" }} id='res-found-ban-subtext'><font id='res-found-ban-subtext' color="#555555">Once a patient's dental pattern is uploaded, our AiCAD system takes over, 
                                                                                    leveraging Deep Learning algorithms to extract complex data from a large .icad database. 
                                                                                    It can incorporate hundreds of dental features simultaneously, making the design process many 
                                                                                    orders of magnitude faster than other methods. By matching a patient's dental pattern with a 
                                                                                    pattern from the database, the design process can be expedited almost instantly using a cloud 
                                                                                    application with a Pay-Per-CAD business model.</font> </p>
                                                                                   
                        <p style={{ fontFamily: "Inter, sans-serif" }} id='res-found-ban-subtitle-1'><font id='res-found-ban-subtitle-1' color="#22303E">iCAD Dental Platform(Paas)</font> </p>
                       
                        <p style={{ fontFamily: "Inter, sans-serif" }} id='res-found-ban-subtext'><font id='res-found-ban-subtext' color="#555555">Our patented encoding method transforms STL files into 
                                                                                    “Segmented Surface Matrixes” (SSM), which map all 3D anatomical features consistently. Design files are easily 
                                                                                    created by allocating corresponding data values from a database and patient scan. Using an oral scanner, a small 
                                                                                    dataset is populated to represent the patient’s pattern. The dataset is much smaller than the corresponding STL 
                                                                                    file, making it faster to upload to iCAD’s PaaS</font> </p>
                    </div>
               </div>
        </div>
      );
}