import { BrowserRouter, Routes, Route } from "react-router-dom";
import LabPage from './pages/LabPage.jsx';
import ResearchPage from './pages/ResearchPage.jsx';
import HomePage  from "./pages/HomePage.jsx";
import CtpPage  from "./pages/CtpPage.jsx";
import ScrollToTopOnMount from './components/ScrollToTopOnMount';

export default function App() {

    return (
        
      <div className="app">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<><ScrollToTopOnMount /><HomePage/></>}/>
          <Route path="/ctp" element={<CtpPage/>}/>
          <Route path="/lab" element={<><ScrollToTopOnMount /><LabPage/></>} />
          <Route path="/research" element={<><ScrollToTopOnMount /><ResearchPage/></>} />
        </Routes>
      </BrowserRouter>
      </div>

    );
  }
