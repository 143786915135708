import icadlogo from '../media/White.png'
// import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { Link } from 'react-router-dom';
import { HashLink as Links } from 'react-router-hash-link';



import {
    AppBar,
    Toolbar,
    makeStyles,
    Button,
    IconButton,
    Drawer,
  } from "@material-ui/core";
  import MenuIcon from "@material-ui/icons/Menu";
  import React, { useState, useEffect } from "react";


const useStyles = makeStyles(() => ({

    drawerContainer: {
      padding: "18px 20px 10px",
      width: "100%",
    
    },
  }));

export default function NavBar() {

    const { drawerContainer } = useStyles(); 
    const path = '/lab#rx'; 
    const url = `${window.location.protocol}//${window.location.hostname}:${window.location.port}${path}`;
    

    console.log(url)
  
  
    const [state, setState] = useState({
      mobileView: false,
      drawerOpen: false,
    });
  
    const { mobileView, drawerOpen } = state;
  
    useEffect(() => {
      const setResponsiveness = () => {
        return window.innerWidth < 900
          ? setState((prevState) => ({ ...prevState, mobileView: true }))
          : setState((prevState) => ({ ...prevState, mobileView: false }));
      };
  
      setResponsiveness();
  
      window.addEventListener("resize", () => setResponsiveness());
  
      return () => { 
        window.removeEventListener("resize", () => setResponsiveness());
      };
    }, []);
  
    const displayDesktop = () => {
      return (
        <Toolbar>
         
          <div>{getMenuButtons()}</div>
        </Toolbar>
      );
    };
  
    const displayMobile = () => {
      const handleDrawerOpen = () =>
        setState((prevState) => ({ ...prevState, drawerOpen: true }));
      const handleDrawerClose = () =>
        setState((prevState) => ({ ...prevState, drawerOpen: false }));
  
      return (
        <Toolbar>
          <IconButton id='icon-btn-navbar' onClick={handleDrawerOpen}
          >
            <MenuIcon />
          </IconButton>
  
          <Drawer
            {...{
              anchor: "right",
              open: drawerOpen,
              onClose: handleDrawerClose,
            }}
          >
            <div className={drawerContainer}>{getDrawerChoices()}</div>
          </Drawer>
          <Link to="/"> 
            <img id='icadlogo-navbar-drawer' src={icadlogo}  alt='logo'/>
          </Link> 
        </Toolbar>
      );
    };
  
    const getDrawerChoices = () => {
     
        return (
            <Stack spacing={{ xs: 1, sm: 2, md: 3 }} direction="column">
                <Button id='lab-btn-drawer' style={{textTransform: 'none', fontFamily: "Inter, sans-serif"}}  component={Link}  to='/lab' variant="text">Dental Lab</Button>
                <div id='line'></div>
                <Button id='research-btn-drawer' style={{textTransform: 'none'}} component={Link}  to='/research' variant="text">Research</Button>
                <div id='line'></div>
                <Button id='research-btn-drawer' style={{textTransform: 'none'}} to='/research' variant="text"><Links style={{color: '#FFFFFF'}} to={url}>Rx</Links></Button>
                <div id='line'></div>

            </Stack>
        );
    };
  
    const getMenuButtons = () => {
   
        return (
            <div className='nav-bar'>
              <Link to="/"> 
                  <img id='icadlogo-navbar' src={icadlogo}  alt='logo'/>
              </Link>       
       
        <div className="navbar-buttons">
        <Stack id='navbar-buttons-stack' spacing={{ xs: 1, sm: 2, md: 1 }} direction="row">
            <Button id='research-btn' style={{textTransform: 'none'}}  component={Link}  to='/research' variant="text">Research</Button>
            <Button id='lab-btn' style={{textTransform: 'none'}} component={Link}  to='/lab' variant="text">Dental Lab</Button>
            <Button id='lab-btn' style={{textTransform: 'none', color: '#000000'}} to='/research' variant="text"><Links style={{color: '#FFFFFF'}} to={url}>Rx</Links></Button>
        </Stack>
        </div>
        </div>
        );
    
    };
    return (
        
            <AppBar id='nav-bar' color="transparent" elevation={0}>
            {mobileView ? displayMobile() : displayDesktop()}
            </AppBar>
      
      );
}