import contactbck from '../media/contactbck.png'
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { createTheme } from '@material-ui/core/styles'
import { useState } from 'react';
import axios from 'axios';


export default function ContactBan() {

  const [name, setName] = useState('');
  const [company, setCompany] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [formSubmitted, setFormSubmitted] = useState(false); // new state variable

  const handleSubmit = (event) => {
    
    event.preventDefault();
    const name = event.target.querySelector('#name-text-field').value;
    const email = event.target.querySelector('#email-text-field').value;
    const company = event.target.querySelector('#company-text-field').value;
    const message = event.target.querySelector('#message-text-field').value;
    const subject = 'Home Page'

    console.log(`Submitting form: name=${name}, company=${company}, email=${email},email=${message}, subject=${subject} `);

    axios.post('https://fg1mud6js1.execute-api.ca-central-1.amazonaws.com/Prod/contact', { name, company, email, message, subject })
      .then(response => {
        console.log(response.data);

        // Clear the form data by resetting the state variables
        setName('');
        setCompany('');
        setEmail('');
        setMessage('');
        setFormSubmitted(true);
        
      })
      .catch(error => {
        console.log(error);
      });
  };

    const theme = createTheme({
      typography: {
        fontFamily: 'Open Sans, sans-serif',
      },
        overrides: {
          MuiBox: {
            root: {
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              height: '100vh'
            }
          }
        },
        breakpoints: {
          values: {
            xs: 200,
            sm: 700,
            md: 960,
            lg: 1280,
            xl: 1900
          }
        }
      });


    return (
        <div className='contact-ban-parent'>
           <div id='contact-ban-child-left'>
                <div id="contact-ban-child-left-text">
                    <p style={{ fontFamily: "Public Sans, sans-serif" }} id="joinus-text-1"> 
                        <font  color="#243B56">Join </font> 
                        <font color="#55bad5" style={{fontWeight:'450'}}>Us.</font>
                    </p>
                    <p style={{ fontFamily: "Inter, sans-serif" }} id='joinus-text-2'><font  color="#243B56">Join the iCAD offering. Contact us today to learn more about partnerships. </font> </p>
                </div>
           </div>

          {!formSubmitted ? ( // show the form if it's not submitted yet
           <div id='contact-ban-child-right'>
           
           <img id='contact-img' src={contactbck}  alt='logo'/>
           
                <Box 
                    component="form"
                    sx={{
                      '& .MuiTextField-root': { 
                        m: 1, 
                        width: '22vw', 
                        height: '6ch', 
                        '& .MuiInputLabel-root': {
                          fontSize: '0.7vw',
                        },
                        [theme.breakpoints.down('lg')]: { 
                          width: '25vw',
                          height: '5.5ch',                           
                          '& .MuiInputLabel-root': {
                            fontSize: '1vw',
                          }, 
                        } ,
                        [theme.breakpoints.down('md')]: { 
                            m: 0.5, 
                            width: '28vw',
                            height: '5.4ch',
                            '& .MuiInputLabel-root': {
                              fontSize: '1.15vw',
                            }, 
                          
                           
                        } ,
                        [theme.breakpoints.down('sm')]: { 
                          m: 0.7, 
                          width: '33vw',
                          height:'4.5ch',
                          '& .MuiInputLabel-root': {
                            fontSize: '1.5vw',
                          },  
                        } ,

                        [theme.breakpoints.down('xs')]: { 
                            m: 0.5, 
                          width: '50vw',
                          height:'5ch',
                          '& .MuiInputLabel-root': {
                            fontSize: '2.3vw',
                          },  
                           },
                          },
                    }}
                    onSubmit={handleSubmit}
                    >
                        <div id='text-form'>
                        <TextField
                        
                        id="name-text-field"
                        label="Name"
                        variant="outlined"
                        InputLabelProps={{
                          style: { fontFamily: 'Open Sans, sans-serif' },
                        }}
                        inputProps={{
                          style: {       
                            fontFamily: 'Open Sans, sans-serif' },
                        
                        }}
                        value={name} // Bind the value of the input to the state variable
                        onChange={(event) => setName(event.target.value)} // Update the state variable when the input value changes
                        />

                        <TextField
                        
                        id="company-text-field"
                        label="Company"
                        variant="outlined"
                        InputLabelProps={{
                          style: { fontFamily: 'Open Sans, sans-serif' },
                        }}
                        inputProps={{
                          style: {       
                            fontFamily: 'Open Sans, sans-serif' },
                        
                        }}
                        value={company}
                        onChange={(event) => setCompany(event.target.value)}
                        />
                        <TextField
                        
                        id="email-text-field"
                        label="Email"
                        variant="outlined"
                        InputLabelProps={{
                          style: { fontFamily: 'Open Sans, sans-serif' },
                        }}
                        inputProps={{
                          style: {       
                            fontFamily: 'Open Sans, sans-serif' },
                        
                        }}
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                        />

                      <TextField
                        id='message-text-field'
                        label="Message"
                        variant="outlined"
                        InputLabelProps={{
                          style: { fontFamily: 'Open Sans, sans-serif' },
                        }}
                        inputProps={{
                          style: {       
                            fontFamily: 'Open Sans, sans-serif' },
                        
                        }}
                        value={message}
                        onChange={(event) => setMessage(event.target.value)}
                        multiline
                        rows={3}
                      />
                        <Button id='touch-btn' style={{textTransform: 'none'}} variant="contained" type="submit">Get in touch</Button>
                    </div>
                </Box>

           </div>
          ) : ( // show the success message if the form is submitted
          <div id='success-message'>
          <div id='success-message-icon'>
            <svg width="80" height="80" viewBox="0 0 90 90" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M22.5 45L35.1562 57.6562L67.5 25.3125" stroke="#55bad5" strokeWidth="9" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </div>
          <div style={{ fontFamily: "Inter, sans-serif", color:"#243B56" }} id='success-message-text'>
            <h2 id='success-message-text'>Thank you for your message!</h2>
          </div>
        </div>
      )}

        </div>
      );
}
