import '../App.css';
import '../css/navbar.css'
import '../css/LabPage/lab-ban.css'
import '../css/LabPage/lab-own.css'
import '../css/LabPage/lab-prod.css'
import '../css/LabPage/lab-print.css'
import '../css/LabPage/lab-contact.css'
import '../css/LabPage/lab-depart.css'

import '../css/LabPage/map.css'

import '../css/footer.css'
import NavBar from '../components/NavBar';
import LabBan from '../components/LabBan';
import LabOwn from '../components/LabOwn';
import LabProducts from '../components/LabProducts'
import LabPrint from '../components/LabPrint'
import LabContact from '../components/LabContact'
// import LabDepart from '../components/LabDepart'

import Map from '../components/Map'

import Footer from '../components/Footer'

function LabPage() {
    return (
    //   <div className="App">
    <div>
        <NavBar/>
        <LabBan/>
        <LabOwn/>      
        <LabProducts/>
        {/* <LabDepart/> */}
        <LabPrint/>
        <LabContact/>
        <Map/>
        <Footer/>
      </div>
    );
  }
  
  export default LabPage;