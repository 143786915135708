import React, { useState, useEffect } from 'react';
import team1 from '../media/team1.png';
import team2 from '../media/team2.png';
import team3 from '../media/team3.png';
import team4 from '../media/team4.png';



const ImageCarousel = () => {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
  
    useEffect(() => {
      // Set up an interval to update the image index every 2 seconds
      const interval = setInterval(() => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % 4);
      }, 2000);
  
      return () => {
        // Clean up the interval on component unmount
        clearInterval(interval);
      };
    }, []);
  
    const images = [
      {src:team1,title:'Fabien Columbe', subtitle:'Chief Executive Officer'},
      {src:team2,title:'Nadine Laviolette', subtitle:'Chief Financial Officer'},
      {src:team3,title:'Burak Karaceper', subtitle:'Chief Operating Officer'},
      {src:team4,title:'Cengiz Karaceper', subtitle:'Chief Production Officer'},
    ];
  
    return (
      <div className="image-carousel">
        {images.map((image, index) => (
         <div key={index} className={index === currentImageIndex ? 'active' : ''}>
            <div>
         <img src={image.src} alt={image.title} 
        //  className={index === currentImageIndex ? 'active' : ''}
         />
        <div className="image-info">
            <p className="title">{image.title}</p>
            <p className="subtitle">{image.subtitle}</p>
          </div>
       </div>
       </div>
            // style={{ order: (index - currentImageIndex + 4) % 4 }
        ))}
      </div>
    );
  };
  
  export default ImageCarousel;
  
