import React, { useRef } from "react";
import { useResizeDetector } from 'react-resize-detector';
import Slider from "react-slick";
import ResLink from './ResLinks';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const items = [
    { key: "igps", title: "iGPS", smallParagraph: "Guided prep and computer aided\ndesign services for oral crown\nprocedures.", paragraph: "For the first time ever, the iGPS System provides step- by-step instructions to visually guide dentist’s prep before operating (Guided prep) which results in\nfaster, more accurate preparation and installation." },
    { key: "ortho", title: "Orthodontics", smallParagraph: "Allows for orthodontic procedures\nthrough Rapid Design.", paragraph: "Allows for orthodontic procedures through Rapid Design." },
    { key: "cavity", title: "Cavity", smallParagraph: "Cavity detection using Ai\npattern mapping.", paragraph: "Cavity detection using Ai pattern mapping." },
]

const MyCarousel = () => {
    const resizeDetector = useResizeDetector();
    const slides = useRef(null);
    const navigation = useRef(null);

    return (
        <div ref={resizeDetector.ref} className="carousel-container" style={{
            '--width': `calc(${resizeDetector.width}px - 25vw)`
          }}>

            <div id='res-stack-wrapper'>
                <div id='res-stack-title'>
                    <font style={{ fontFamily: "Public Sans, sans-serif" }} id='res-stack-title-1' color="#243B56">Technology </font> 
                    <font style={{ fontFamily: "Public Sans, sans-serif" }} id='res-stack-title-2' color="#55B9D5"><b>Stacks.</b></font>
                    <p id='res-stack-text'>
                        <font style={{ fontFamily: "Inter, sans-serif" }} color="#555555">AiCAD’s cloud computing allows for many services to be offered through the 
                                                                    same platform. Dentists can complete multiple Ai assisted processes using one scan and transaction.
                        </font>
                            </p> 
               </div>
               </div>
            <div className="stack-container">
                <Slider
                    ref={slides}
                    asNavFor={navigation.current}
                    infinite
                    slidesToShow={resizeDetector.width > 768 ? 3 : 1}
                    arrows={false}
                    centerMode
                >
                    {Array.from({ length: 2 }).map(_ => 
                        items.map(item =>
                            <div className="stack-slide">
                                <div className="stack-top">
                                    <img src={require(`../media/imgs/stack_${item.key}.svg`)} />
                                    <img className="stack-text-img" src={require(`../media/imgs/stack_${item.key}_text.svg`)} />
                                </div>
                                <div className="stack-slide-text">
                                    <h5><b>{item.title}</b> Stack</h5>
                                    <p>{item.smallParagraph}</p>
                                </div>
                            </div>
                        )
                    )}
                </Slider>
                <img className="stack-base" src={require(`../media/imgs/stack_base.svg`).default} />
            </div>
            <div className="navigation">
                <div className="text-slide-container">
                    <Slider
                        ref={navigation}
                        asNavFor={slides.current}
                        infinite
                        slidesToShow={1}
                        arrows={false}
                    >
                        {Array.from({ length: 2 }).map(_ => 
                            items.map(item =>
                                <div className="text-slide">
                                    <h5><b>{item.title}</b> Stack</h5>
                                    <p>{item.paragraph}</p>
                                </div>
                            )
                        )}
                    </Slider>
                </div>

                <button className="learn-more">
                    Scan Below
                    <img src={require(`../media/imgs/icon-lock.svg`).default}/>
                </button>
                <div className="navigation-button-container">
                    <button id='next-btn' onClick={() => slides.current.slickPrev()}><img src={require(`../media/imgs/icon-arrow.svg`).default}/></button>
                    <button id='next-btn' onClick={() => slides.current.slickNext()}><img src={require(`../media/imgs/icon-arrow.svg`).default}/></button>
                </div>

            </div>
            <ResLink/>
        </div>
    )
}

export default MyCarousel;