
import React, { useState, useEffect } from 'react';
import DD from '../media/DigitalDentistry.mov';
import R from '../media/Removables.mov';
import CB from '../media/CB.mov';
import CB2 from '../media/CB.png';
import DD2 from '../media/DD.png';
import R2 from '../media/Removables.png';


  
function DepartList() {

    const [CBbackgroundVideo, CBsetBackgroundVideo] = useState(CB);
    const [CBimage, CBsetImage] = useState(CB2);

    const [DDbackgroundVideo, DDsetBackgroundVideo] = useState(DD);
    const [DDimage, DDsetImage] = useState(DD2);

    const [RbackgroundVideo, RsetBackgroundVideo] = useState(R);
    const [Rimage, RsetImage] = useState(R2);

    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const handleResize = () => {
          setIsMobile(window.innerWidth < 800);
          if (window.innerWidth < 800) {
            CBsetBackgroundVideo("");
            CBsetImage(CB2);

            DDsetBackgroundVideo("");
            DDsetImage(DD2);

            RsetBackgroundVideo("");
            RsetImage(R2);
          } else {
            CBsetBackgroundVideo(CB);
            CBsetImage("");

            DDsetBackgroundVideo(DD);
            DDsetImage("");

            RsetBackgroundVideo(R);
            RsetImage("");
          }
        };

        handleResize();
        window.addEventListener("resize", handleResize);
        return () => {
          window.removeEventListener("resize", handleResize);
        };
      }, []);

    return (
        <div className='lab-product-ban' >
               <div id='lab-product-title'>
                    <font style={{ fontFamily: "Public Sans, sans-serif" }} id='lab-product-title-1' color="#243B56">Our </font> 
                    <font style={{ fontFamily: "Public Sans, sans-serif" }} id='lab-product-title-2' color="#5CB099"><b>Strength.</b></font>
                    <p id='lab-product-text'>
                        <font style={{ fontFamily: "Inter, sans-serif" }} color="#243B56">The strength of iCAD lies in its experienced team. Our technicians play a 
                        crucial role in helping patients achieve optimal oral health, using their technical expertise & creative flair to produce durable, 
                        natural-looking dental prostheses that improve patient's quality of life & boost their confidence. 
                                                     
                        </font>
                            </p> 
               </div>
               <div className='lab-depart-parent'>

                <div className="depart-video-div">

                      <div className="depart-child-1">
                        {isMobile ? (
                            <img id='depart-img' src={CBimage}  alt='logo'/>
                        ) : (
                            <video autoPlay loop muted id="depart-video" poster="../media/lab.png">
                                  <source src={CBbackgroundVideo} type="video/mp4"/>
                              </video>
                        )}
                              {/* <video autoPlay loop muted id="depart-video" poster="../media/lab.png">
                                  <source src={CBbackgroundVideo} type="video/mp4"/>
                              </video>
                              <img id='depart-img' src={CBimage}  alt='logo'/> */}
                      </div>
                      <div id='depart-child-2' style={{ fontFamily: "Inter, sans-serif" }}>

                              <p id='depart-heading'>
                                  <font  color="#5CB099">Fixed Prosthetics
                                  </font>
                              </p> 
                              <p id='depart-text'>
                                  <font  color="#22303E">Our detail oriented team specializes in the fabrication of dentures & other dental prosthetics. We use techniques to 
                                  ensure that the denture is durable, comfortable  & aesthetically pleasing. 
   
                                  </font>
                              </p> 

                          </div>
                      </div>


                      <div className="depart-video-div">

                      <div className="depart-child-1">
                        {isMobile ? (
                            <img id='depart-img' src={Rimage}  alt='logo'/>
                        ) : (
                            <video autoPlay loop muted id="depart-video" poster="../media/lab.png">
                                  <source src={RbackgroundVideo} type="video/mp4"/>
                              </video>
                        )}
                            
                      </div>
                      <div id='depart-child-2' style={{ fontFamily: "Inter, sans-serif" }}>
                              <p id='depart-heading'>
                                  <font  color="#5CB099">Removable Dentures
                                  </font>
                              </p> 

                              <p id='depart-text'>
                                  <font  color="#22303E">Our team specializes in designing & fabricating removable dental prostheses, such as complete dentures & partial dentures. We 
                                  use a variety of materials & techniques to create prostheses that fit the unique needs. 
                                  </font>
                              </p> 
                          </div>
                      </div>

                <div className="depart-video-div">

                <div className="depart-child-1">
                        {isMobile ? (
                            <img id='depart-img' src={DDimage}  alt='logo'/>
                        ) : (
                            <video autoPlay loop muted id="depart-video" poster="../media/lab.png">
                                  <source src={DDbackgroundVideo} type="video/mp4"/>
                              </video>
                        )}
                             
                      </div>

                    <div id='depart-child-2' style={{ fontFamily: "Inter, sans-serif" }}>
                      <p id='depart-heading'>
                          <font  color="#5CB099">Digital Dentistry 
                          </font>
                      </p> 

                      <p id='depart-text'>
                          <font  color="#22303E">Our digital dentistry department uses cutting-edge technology, including CAD/CAM, to provide high-quality dental care. 
                          Our skilled technicians create precise & aesthetically pleasing dental restorations with exceptional accuracy & function.
                          </font>
                      </p> 
                    </div>

                </div>

                </div>
      </div>
    );
  }
  
  export default DepartList;