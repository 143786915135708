import ban from '../media/banback1.png'

export default function LabBan() {
    return (
        <div className='lab-ban' >
                
                <img id='lab-background-img' src={ban}  alt='logo'/>
                <div className="lab-ban-overlay">
                <div className="lab-text-container">
                    <p style={{ fontFamily: "Public Sans, sans-serif" }} id='lab-heading-1'>Full Service <b>Dental Lab.</b></p>
                    <p style={{ fontFamily: "Inter, sans-serif" }} id='lab-heading-2'><b>At the Cross Roads of Art and Science</b></p>
                    <p style={{ fontFamily: "Inter, sans-serif" }} id='lab-heading-3'>August 1st, 2022, iCAD Dental inc. has purchased Burak’s and Cengiz’s shares 
                            in Smile Dental Laboratory inc. well known in Ottawa for the past 15 years 
                            for its outstanding quality dental works.  Having our research / engineering 
                            teams now joining force with Burak’s and Cengiz’s dental lab teams is 
                            enabling iCAD Dental to blend our revolutionary AI based design 
                            technologies (AiCAD) with same high quality dental laboratory services.</p>

                </div>
            </div>
        </div>
      );
}