import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App1 from './App';
import reportWebVitals from './reportWebVitals';

import "./fonts/Inter-3.19/Inter Desktop/Inter-V.ttf";
// import "./fonts/PublicSans-Regular.ttf";
import "./fonts/public-sans-v2.001/fonts/ttf/PublicSans-Regular.ttf"
import "./css/fonts.css";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App1 />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
