import ToothPt from '../media/ToothPt.mov'

export default function VisionBan() {
    return (
        <div className='vision-ban'>
            <div id='vision-text-flex'>
                    <font style={{ fontFamily: "Public Sans, sans-serif" }} id='vision-text-flex-1' color="#243B56">Our </font> 
                    <font style={{ fontFamily: "Public Sans, sans-serif", fontWeight: 900 }} id='vision-text-flex-2' color="#55bad5">Vision.</font>

                    <p id='vision-description'>
                    <font  style={{ fontFamily: "Inter, sans-serif" }} color="#243B56">Dental patterns recognition is the key to automate design. On demand custom design files with matching guided surgery 
                                                        will tremendously improve 3D printed chairside solutions. Design process can be augmented by retrieving files sharing similar 
                                                        dental patterns from digital impressions data base.  
                    </font>
                        </p> 
                </div>
                
           <div id='tooth-pt-video-parent'>
                <video style={{maxHeight:'100%'}} autoPlay loop muted playsInline disablePictureInPicture id="tooth-pt-video">
                    <source src={ToothPt} type="video/mp4"/>
                </video>
                </div>

            
                
               
        </div>
      );
}
